.user_background {
  background-image: url("../assests/userdashboard_bg.svg");
  width: 100%;
  background-repeat: no-repeat;
}

.card_pattern {
  background: linear-gradient(
      113.04deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 41.42%,
      rgba(139, 216, 149, 0) 51.67%,
      rgba(139, 216, 149, 0.6) 100%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06));

  border: 3px solid;

  border-image-source: linear-gradient(
    113.04deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 41.42%,
    rgba(139, 216, 149, 0) 51.67%,
    rgba(139, 216, 149, 0.6) 100%
  );
  box-shadow: -20.266666412353516px 20.266666412353516px 20.266666412353516px
    0px #ffffff1a inset;

  box-shadow: 20.266666412353516px -20.266666412353516px 20.266666412353516px 0px
    #c2c2c21a inset;
}

.centerseparatorMobile {
  width: 95%;
  margin: auto;
  display: flex;
  line-height: 0.5em;
  color: rgba(255, 255, 255, 0.2);
}
.centerseparatorMobile::before,
.centerseparatorMobile::after {
  content: "";
  display: inline-block;
  flex-grow: 1;
  margin-top: 14px;
  background: rgba(255, 255, 255, 0.2);
  height: 1px;
  margin-right: 10px;
  margin-left: 10px;
}
.centerText {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
}
