.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  display: none;
  position: absolute;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1100;
}

.dropdown_content a {
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown_content {
  display: block;
}
