/* .App {
  background: #e4e1da;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none !important;
}



.App-header {
  background: linear-gradient(180deg, #000000 0%, #231c04 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus-visible {
  outline: none !important;
}
.formatted_text {
  animation: typing 1.5s steps(10);
}

@keyframes typing {
  100% {
    left: 100%;
    margin: 2px;
  }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
