@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;



body::-webkit-scrollbar {
    width: 0.6em;
    /* height: 0.6em; */
}

body::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: #000000;
}

body::-webkit-scrollbar-thumb {
    background-color: #333333;
    outline: 1px solid #333333;
    border-radius: 2px;
}

.custom-scroll::-webkit-scrollbar {
    height: 0.2em;
    width: 0.2em;

}

.custom-scroll::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color:transparent;
    outline: none;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #333333;
    outline: 1px solid #333333;
    border-radius: 2px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1)
            brightness(100%)
            sepia(100%)
            saturate(10000%)
            hue-rotate(180deg)
}
.css-166bipr-Input {
    color:#ffffff !important;
}

/* .css-166bipr-Input{
    color:#ffffff !important;
}

.css-13sfsp-control {
    border-color:#ffffff33;
    box-shadow:none;
}
.css-13sfsp-control:hover {
    border-color:#ffffff33;
    box-shadow:none;
}
.css-13sfsp-control:focus {
    border-color:#ffffff33;
    box-shadow:none;
} */

@layer base {

    .btn{
        @apply rounded-md  md:text-lg  font-semibold focus:outline-none transition duration-300 ease-in;
    }
    .input {
        @apply block w-full rounded-md border-0 text-sm font-semibold px-3 py-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6
    }

    .label {
        @apply block text-sm font-medium leading-6 text-gray-900
    }

    .select {
        @apply block w-full rounded-md border-0 text-sm font-semibold px-3 py-3 pr-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6
    }
}

.full-width-table {
    width: 100%;
    table-layout: auto;
    /* This is the key property */
}
